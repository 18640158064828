import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../navigation/Link';
import Image from '../../general/Image';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {NewsArticle} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';


/**
 * @fero
 */

const ImageWrapper = (props) => {
    const {newsArticle} = props;
    const {thumbnail, description_short} = newsArticle;
    const hasDescription = description_short != null && description_short != "";
    return hasDescription ? 
    <div className="px-4" 
        dangerouslySetInnerHTML={{__html: description_short}}
    /> :
    <Image
        src={thumbnail}
        wrapperClassName='news-image'
    />;
};

class NewsImage extends React.PureComponent {
    static propTypes = {
        newsArticle: NewsArticle.isRequired,
    };

    static defaultProps = {
        newsArticle: {},
    };

    render() {
        const {newsArticle} = this.props;
        let path = null;
        let queryParams = {};

        if(newsArticle.html)
        {
            path = ROUTES.NEWS_DETAIL;
            queryParams = {[QUERY_PARAMS.ID_NEWS]: newsArticle.id};
        }
        else if(newsArticle.id_product)
        {
            path = ROUTES.PRODUCT_DETAILS;
            queryParams = {[QUERY_PARAMS.ID_PRODUCT]: newsArticle.id_product};
        }
        else if(newsArticle.id_product_group)
        {
            path = ROUTES.HOME;
            queryParams = {[QUERY_PARAMS.ID_PRODUCT_GROUP]: newsArticle.id_product_group};
        }

        if(newsArticle.url != null) 
        {
            return  <a
                className="news-image-wrapper"
                href={newsArticle.url}
            >
                <ImageWrapper newsArticle={newsArticle}/>
            </a>;
        }
        else if(path != null)
        {
            return  <Link
                className="news-image-wrapper"
                to={path}
                queryParams={queryParams}
            >
                <ImageWrapper newsArticle={newsArticle}/>
            </Link>;
        } 
        else 
        {
            return <div className="news-image-wrapper">
                <ImageWrapper newsArticle={newsArticle}/>
            </div>;
        }
    }

}

export default NewsImage;