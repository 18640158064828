import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import EmployeesSelect from '../../project/EmployeesSelect';
import CategoriesTreeSelect from '../../project/CategoriesTreeSelect';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import DateRangePicker from '../../general/DateRangePicker';
import InputText from '../../general/InputText';
import {getQueryParam, createSetQueryParamValue, navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class NewsParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.NEWS_ID_PRODUCT_GROUP]: undefined,
            [QUERY_PARAMS.NEWS_PHRASE]: undefined,
            [QUERY_PARAMS.NEWS_ID_AUTHOR]: undefined,
            [QUERY_PARAMS.NEWS_ACTIVE_AT]: undefined,
            [QUERY_PARAMS.NEWS_LAST_CHANGE]: undefined,
            [QUERY_PARAMS.NEWS_LIMIT]: undefined,
        });
    };

    render() {
        const {location} = this.props;
        const productGroupId = getQueryParam(location, QUERY_PARAMS.NEWS_ID_PRODUCT_GROUP);
        const phrase = getQueryParam(location, QUERY_PARAMS.NEWS_PHRASE);
        const authorId = getQueryParam(location, QUERY_PARAMS.NEWS_ID_AUTHOR);
        const activeAt = getQueryParam(location, QUERY_PARAMS.NEWS_ACTIVE_AT);
        const lastChange = getQueryParam(location, QUERY_PARAMS.NEWS_LAST_CHANGE);
        const hasFilter = productGroupId != null || phrase != null || authorId != null || activeAt != null || lastChange != null;
        return <ColumnFilterLayout
            filters={[
                [
                    {
                        label: <Trans>Vyhľadávanie: </Trans>,
                        selector: <InputText
                            className="full-size-width"
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.NEWS_PHRASE)}
                            value={phrase}
                        />,
                    },
                    {
                        label: <Trans>Produktová skupina: </Trans>,
                        selector: <CategoriesTreeSelect
                            className="full-size-width"
                            value={productGroupId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.NEWS_ID_PRODUCT_GROUP)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Autor: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={authorId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.NEWS_ID_AUTHOR)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                ],
                [
                    {
                        label: <Trans>Aktívne: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.NEWS_ACTIVE_AT)}
                            value={activeAt}
                        />,
                    },
                    {
                        label: <Trans>Posledná zmena: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.NEWS_LAST_CHANGE)}
                            value={lastChange}
                        />,
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            className="full-size-width"
                            onClick={this.clearAllParams}
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>
                    }
                ],
            ]}
        />;
    }

}

export default locationHOC(NewsParams);