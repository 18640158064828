import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import generalFailedPC from '../fetch/generalFailedPC';
import FormModal from '../fetch/FormModal';
import Tooltip from '../general/Tooltip';
import NewsForm from './form/NewsForm';

/**
 * @fero
 */

class EditNews extends React.PureComponent {
    static propTypes = {
        newsId: PropTypes.number.isRequired,
        onFinish: PropTypes.func,
    };

    render() {
        const {newsId, onFinish} = this.props;
        
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Upraviť novinku</Trans>}>
                    <Button>
                        <Icon type="edit"/>
                        <span className="ml-2">
                            <Trans>Upraviť</Trans>
                        </span>
                    </Button>
                </Tooltip>
            }
            modalClassName="large-modal"
            values={{newsId: newsId, isEdit: true}}
            onFinishSuccessful={onFinish}
            title={<Trans>Upraviť novinku</Trans>}
            Form={NewsForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť novinku.`)}
        />;
    }
}

export default EditNews;