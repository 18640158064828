import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../general/Tooltip';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {locationHOC} from '../../locationProvider/locationHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {createFetchNewsRemove} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class NewsArticleRemove extends React.PureComponent {
    static propTypes = {
        newsArticleId: PropTypes.number.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    onFinish = () => {
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        reloadData([GLOBAL_DATA.NEWS_CURRENT, GLOBAL_DATA.NEWS]);
    };

    render() {
        const {newsArticleId} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstrániť novinku</Trans>}>
                    <Button type="danger" icon="close"/>
                </Tooltip>
            }
            values={{}}
            onFinish={this.onFinish}
            title={<Trans>Odstránenie novinky</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť novinku?</Trans>,
                createFetchNewsRemove(newsArticleId),
            )}
            Response={null}
            Failed={generalFailedPC(t`Novinku sa nepodarilo odstrániť.`)}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(NewsArticleRemove));