import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import {getTreePath} from '../../lib/object';
import {CategoryInTree} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import {TreeSelect} from 'antd';
import {Trans, t} from '@lingui/macro';
const TreeNode = TreeSelect.TreeNode;

/**
 * @fero
 */

class CategoriesTreeSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        onChange: PropTypes.func.isRequired,
        allowClear: PropTypes.bool,
        [GLOBAL_DATA.CATEGORY_TREE]: CategoryInTree,
    };

    static defaultProps = {
        allowClear: false
    };

    componentDidMount() {
        const {onChange, value, allowClear} = this.props;
        if (value == null && !allowClear) {
            const categoryTree = this.props[GLOBAL_DATA.CATEGORY_TREE];
            const first = categoryTree.id;
            onChange(first);
        }
    }

    tree(node) {
        return <TreeNode
            value={node.id}
            title={node.name}
            key={node.id}
        >
            {node.children != null ?
                node.children.map((child) => {
                    return this.tree(child)
                }) :
                null}
        </TreeNode>;
    }

    render() {
        const {value, [GLOBAL_DATA.CATEGORY_TREE]: categoryTree, ...props} = this.props;
        const pathKeys = getTreePath(categoryTree, value);
        if (categoryTree != null) {
            return <TreeSelect
                showSearch={true}
                placeholder={<Trans>Vyberte...</Trans>}
                filterTreeNode={(input, node) => {
                    return node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                treeNodeFilterProp="value"
                treeDefaultExpandAll={false}
                treeDefaultExpandedKeys={pathKeys}
                value={value}
                {...props}
            >
                {this.tree(categoryTree)}

            </TreeSelect>
        } else {
            return null;
        }
    }

}

export default withDataHOC([GLOBAL_DATA.CATEGORY_TREE])(CategoriesTreeSelect);