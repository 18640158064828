import React from 'react';
import PropTypes from 'prop-types';
import NewsArticle from './news/NewsArticle';
import NavigateButton from '../general/NavigateButton';
import locationHOC from '../locationProvider/locationHOC';
import {QUERY_PARAMS} from '../../constants/navigation';
import {getQueryParam} from '../../lib/url';
import {News as NewsDef, Location} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * Displays news provided in news.
 * Should provide navigation to edit and detail pages. These pages needs to be created separately and relative path
 * to them provided in editPath and detailPath attributes.
 *
 * @fero
 */

class News extends React.PureComponent {
    static propTypes = {
        news: NewsDef.isRequired,
        location: Location.isRequired,
        reload: PropTypes.func,
    };

    static defaultProps = {
        news: [],
    };

    render() {
        const {news, location, reload} = this.props;
        const limit = Number(getQueryParam(location, QUERY_PARAMS.NEWS_LIMIT));
        const pageSize = Number(process.env.DEFAULT_TABLE_PAGE_SIZE);
        if (Array.isArray(news) && news.length > 0) 
        {
            return <React.Fragment>
                {news.map((newsArticle, index) => {
                    return <div key={index} className="m-3">
                        <NewsArticle
                            newsArticle={newsArticle}
                            reload={reload}
                        />
                    </div>
                })}
                {(news.length == limit) ?
                <div className="m-3 d-flex justify-content-center">
                    <NavigateButton
                        type="primary"
                        to={null}
                        queryParams={{[QUERY_PARAMS.NEWS_LIMIT]: (limit+pageSize)}}
                    >
                        <Trans>Zobraziť ďalších</Trans>
                        {' '}{pageSize}{' '}
                        <Trans>noviniek</Trans>
                    </NavigateButton>
                </div> : null}
            </React.Fragment>;
        } 
        else 
        {
            return <h2 className="m-3 color-disabled text-center">
                <Trans>Vyhĺadávaniu nezodpovedajú žiadne novinky.</Trans>
            </h2>;
        }
    }

}

export default locationHOC(News);