import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../general/DatePicker';
import CategoriesTreeSelect from '../../project/CategoriesTreeSelect';
import SingleFileUpload from '../../general/SingleFileUpload';
import locationHOC from '../../locationProvider/locationHOC';
import {checkImage, getInputOnChangeEventValue} from '../../../lib/project';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Radio} from 'antd';
import {createFetchNewsAdd, createFetchNewsDetails, createFetchNewsEdit} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule, optionalRule} from '../../../constants/form';
import ProductSelect from '../../project/ProductSelect';
import InputText from '../../general/InputText';
import MultiLangInput from '../../general/MultiLangInput';
import { GLOBAL_DATA } from '../../../constants/globalData';
import Spinner from '../../graphics/Spinner';
import withDataHOC from '../../dataProvider/withDataHOC';

const FormItem = Form.Item;

/**
 * 
 *
 * @dusan
 */

class NewsForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            newsId: PropTypes.number,
            isEdit: PropTypes.bool.isRequired,
        }).isRequired,
        [GLOBAL_DATA.FETCH_DATA]: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEdit: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            response: null,
            newsArticle: {},
            useImage: false,
            loadingNews: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0);
        this.fetchNews();
    }

    componentDidUpdate(prevProps) {
        const {values} = this.props;
        const {values: prevValues} = prevProps;
        const {newsId} = values;
        const {newsId: prevNewsId} = prevValues;

        if(newsId != prevNewsId)
            this.fetchNews();
    }

    fetchNews = () => {
        const {values, [GLOBAL_DATA.FETCH_DATA]: fetchHandler} = this.props;
        const {newsId} = values;

        if(newsId == null)
        {
            this.setState({
                newsArticle: {},
                loadingNews: false
            });
            return;
        }

        this.setState({loadingNews: true});
        fetchHandler(
            createFetchNewsDetails(),
            {id: newsId, all_lang: true},
            (result) => {
                this.setState({
                    newsArticle: result,
                    useImage: result.description_short == null || result.description_short == '',
                    loadingNews: false,
                });
            }
        );
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {isEdit} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) 
            {
                this.setState({loading: true});
                const fetchFunction = isEdit ? createFetchNewsEdit() : createFetchNewsAdd();
                fetchFunction(formValues).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    onUseImageChange = (useImage) => {
        const {newsArticle} = this.state;
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            description_short: useImage ? null : newsArticle.description_short,
            thumbnail: useImage ? newsArticle.thumbnail : null,
        });
        this.setState({useImage: useImage});
    }

    render() {
        const {values} = this.props;
        const {newsId, isEdit} = values;
        const {newsArticle, loading, loadingNews, useImage} = this.state;
        const {thumbnail, description_short} = newsArticle;
        const {getFieldDecorator} = this.props.form;

        return loadingNews ? <Spinner/> : <Form
            onSubmit={this.handleSubmit}
        >
            {getFieldDecorator('id', {initialValue: newsId})}
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('name', {
                    rules: [mandatoryRule],
                    initialValue: newsArticle.name,
                })(
                    <MultiLangInput
                        type="textarea"
                        className="news-form-input"
                        ref={node => this.first = node}
                        maxLength={255}
                        autoSize={{minRows: 2, maxRows: 10}}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Obsah</Trans>}
            >
                {getFieldDecorator('is_content_image', {
                    initialValue: useImage,
                })(
                    <Radio.Group
                        onChange={(ev) => {
                            const val = getInputOnChangeEventValue(ev);
                            this.onUseImageChange(val);
                        }}
                    >
                        <Radio.Button value={true}><Trans>Obrázok</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Popis</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                className={useImage ? "" : "d-none"}
                label={<Trans>Obrázok</Trans>}
            >
                {getFieldDecorator('thumbnail', {
                    rules: [useImage ? mandatoryRule : optionalRule],
                    initialValue: thumbnail
                })(
                    <SingleFileUpload
                        className="news-form-input"
                        beforeUpload={checkImage}
                        showPreview={true}
                    />
                )}
            </FormItem>
            <FormItem
                className={useImage ? "d-none" : ""}
                label={<Trans>Popis</Trans>}
            >
                {getFieldDecorator('description_short', {
                    rules: [useImage ? optionalRule : mandatoryRule],
                    initialValue: description_short,
                })(
                    <MultiLangInput
                        type="html"
                        hasSave={false}
                        fullHeight={false}
                        resizable={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Produktová skupina</Trans>}
            >
                {getFieldDecorator('id_product_group', {
                    initialValue: newsArticle.id_product_group != null ? newsArticle.id_product_group : null,
                })(
                    <CategoriesTreeSelect
                        className="news-form-input"
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Produkt</Trans>}
            >
                {getFieldDecorator('id_product', {
                    initialValue: newsArticle.id_product != null ? newsArticle.id_product : null,
                })(
                    <ProductSelect
                        className="news-form-input"
                        allowClear={true}
                        showGeneric={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Link</Trans>}
            >
                {getFieldDecorator('url', {
                    initialValue: newsArticle.url != null ? newsArticle.url : null,
                })(
                    <InputText
                        className="news-form-input"
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Aktívna od</Trans>}
            >
                {getFieldDecorator('active_from', {
                    rules: [mandatoryRule],
                    initialValue: newsArticle.active_from != null ? newsArticle.active_from : null,
                })(
                    <DatePicker
                        className="netto-date-picker"
                        placeholder={t`neuvedené`}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Aktívna do</Trans>}
            >
                {getFieldDecorator('active_to', {
                    initialValue: newsArticle.active_to != null ? newsArticle.active_to : null,
                })(
                    <DatePicker
                        className="netto-date-picker"
                        placeholder={t`neuvedené`}
                    />
                )}
            </FormItem>
            <FormItem
                label={<Trans>Detailný popis</Trans>}
            >
                {getFieldDecorator('html', {
                    initialValue: newsArticle.html,
                })(
                    <MultiLangInput
                        type="html"
                        hasSave={false}
                        fullHeight={false}
                        resizable={true}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>;
    }

}

export default Form.create()(
    locationHOC(
        withDataHOC([GLOBAL_DATA.FETCH_DATA])(NewsForm)
    )
);