import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Tooltip from '../../general/Tooltip';
import NewsImage from './NewsImage';
import NewsArticleRemove from './NewsArticleRemove';
import NavigateButton from '../../general/NavigateButton';
import Date from '../../general/Date';
import {Icon} from 'antd';
import {RIGHTS} from '../../../constants/Rights';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {NewsArticle as NewsArticleDef} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import EditNews from '../EditNews';


/**
 * @fero
 */

class NewsArticle extends React.PureComponent {
    static propTypes = {
        newsArticle: NewsArticleDef.isRequired,
    };

    static defaultProps = {
        newsArticle: {},
    };

    render() {
        const {newsArticle, reload} = this.props;
        return <div>
            <h1 className="mb-3">
                {newsArticle.name}
            </h1>
            <NewsImage newsArticle={newsArticle}/>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <div className="mt-2 secondary-text-color d-flex align-items-center flex-wrap">
                    <span className="mr-2">
                        <Trans>Vytvoril: </Trans>
                        {' ' + newsArticle.author + ', '}
                    </span>
                    <span className="mr-2">
                        <Trans>Posledná zmena: </Trans>
                        {' '}
                        <Date dateString={newsArticle.last_change}/>
                    </span>
                    <span className="mr-2">
                        <Trans>Aktívne do: </Trans>
                        {' '}
                        <Date dateString={newsArticle.active_to}/>
                    </span>
                    <EditNews
                        newsId={newsArticle.id}
                        onFinish={reload}
                    />
                    <NewsArticleRemove
                        newsArticleId={newsArticle.id}
                    />
                </div>
            </RightsWrapper>
        </div>;
    }

}

export default NewsArticle;