import React from 'react';
import Helmet from '../project/Helmet';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import News from './News';
import withDataHOC from '../dataProvider/withDataHOC';
import NewsParams from './news/NewsParams';
import {RIGHTS} from '../../constants/Rights';
import {GLOBAL_DATA} from '../../constants/globalData';
import {News as NewsDef} from '../../constants/propTypesDefinitions';
import {t} from '@lingui/macro';
import AddNews from './AddNews';

/**
 * @fero
 */

class NewsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.NEWS]: NewsDef.isRequired,
    };

    reloadData = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.NEWS]);
    };

    render() {
        const news = this.props[GLOBAL_DATA.NEWS];
        return <React.Fragment>
            <Helmet
                title={t`Novinky`}
            />
            <div className="full-size-height overflow-y-auto">
                <NewsParams />
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <AddNews
                        onFinish={this.reloadData}
                    />
                </RightsWrapper>
                <News 
                    news={news}
                    reload={this.reloadData}
                />
            </div>
        </React.Fragment>;
    }

}

export default withDataHOC([GLOBAL_DATA.NEWS, GLOBAL_DATA.RELOAD_DATA])(NewsPage);